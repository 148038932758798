body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-green: #13440f;
  --black: #111;
  --white: #eee;
  --primary-text: var(--black);
  --inverted-text: var(--white);
  --primary-bg: #ffdd00;
  --dark-of-primary-bg: #0f26a6;
  --logo-bg: var(--white);
  --logo-fg: #fac51b;
  --grey: #ccc;
  --box-shadow: 0 0 4px var(--black);
  --home-text: #e2d199;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
  color: var(--white);
  font-size: 16px;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  5. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

a {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  background-color: var(--bg-green);
  height: 100dvh;
  width: 100dvw;
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  background-color: var(--primary-bg);
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  box-shadow: var(--box-shadow);
  justify-content: space-between;

  img {
    height: 12dvh;
    object-fit: contain;
    transition: 100ms;

    &:hover {
      transition: 200ms;
      transform: scale(1.05);
    }
  }

  .link-div {
    flex-wrap: wrap;
    display: flex;
    gap: 15px;

    a {
      display: inline-block;
      width: fit-content;
      padding: 5px 20px;
      background-color: var(--white);
      border-radius: 20px;
      align-items: center;
      text-transform: uppercase;
      border: solid 2px var(--primary-text);
      margin: 0 auto;
      transition: 100ms;
      font-weight: 600;
      color: var(--black);

      &.active {
        color: var(--white);
        background-color: var(--bg-green);
        border-color: var(--black);
      }

      &:hover {
        transition: 300ms;
        transform: scale(1.05);
      }

      &:hover:not(.active) {
        background-color: var(--white);
        color: var(--black);
        border-color: var(--black);
      }
    }
  }
}

.main-div {
  padding: 35px 30px;
}

.search-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto 20px auto;
  gap: 10px;

  .search-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: end;

    .search-btn {
      background-color: rgba(0, 0, 0, 0.1);
      border: solid 2px var(--white);
      border-radius: 10px;
      padding: 2px;
      height: 37.2px;
      width: 37.2px;
      display: grid;
      place-items: center;
      cursor: pointer;

      img {
        height: 20px;
      }

      &:hover {
        border-color: var(--black);
        transition: 300ms;
      }

      &:disabled,
      &:disabled:hover {
        border-color: var(--grey);
        transition: 0;
      }
    }
  }

  .or-span {
    display: grid;
    place-items: center;
    color: rgba(0, 0, 0, 0.5);
    font-size: 25px;
  }

  .search-with-camera {
    width: 290px;
    padding: 5px 10px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    box-shadow: 0 0 1px var(--black);
    cursor: pointer;

    &:focus {
      border: solid 1px var(--black);
      box-shadow: var(--box-shadow);
      transition: 200ms;
    }

    &:hover {
      box-shadow: var(--box-shadow);
      transition: 200ms;
    }

    span {
      color: var(--dark-of-primary-bg);
    }

    img {
      height: 30px;

      svg {
        fill: var(--dark-of-primary-bg);
      }
    }

    &:disabled {
      cursor: not-allowed;

      span {
        color: var(--grey);
      }

      img {
        filter: grayscale(0.6);
      }

      &:hover {
        box-shadow: 0 0 1px var(--black);
      }
    }
  }

  @media (orientation: portrait) {
    & {
      flex-direction: column;
      align-items: center;
    }
  }
}

.text-field-wrapper {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1px;
  cursor: text;

  div {
    font-size: 10px;
    margin-left: 5px;
  }

  input {
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.1);
    outline: none;
    border: solid 2px var(--white);
    border-radius: 10px;
    min-width: 250px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }


    &[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    &::placeholder {
      color: var(--logo-bg);
    }

    &[disabled] {
      color: var(--grey);
      border-color: var(--grey);
      pointer-events: none;
    }

    &:focus {
      border: solid 2px var(--black);
    }
  }
}

.profile-wrapper {
  display: grid;
  gap: 10px;
  place-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.profile-container {
  .create-patient-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;

    button {
      padding: 5px 10px;
      background-color: var(--white);
      color: var(--dark-of-primary-bg);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      box-shadow: 0 0 1px var(--black);
      cursor: pointer;

      &:focus {
        border: solid 1px var(--black);
        box-shadow: var(--box-shadow);
        transition: 200ms;
      }

      &:disabled {
        color: var(--grey);
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        box-shadow: var(--box-shadow);
        transition: 200ms;
      }
    }
  }

  .new-patient-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;

    .patient-fields {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      align-items: center;

      button {
        padding: 5px 10px;
        background-color: var(--white);
        color: var(--dark-of-primary-bg);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        box-shadow: 0 0 1px var(--black);
        cursor: pointer;
        width: fit-content;

        &:focus {
          border: solid 1px var(--black);
          box-shadow: var(--box-shadow);
          transition: 200ms;
        }

        &:disabled {
          color: var(--grey);
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          box-shadow: var(--box-shadow);
          transition: 200ms;
        }
      }
    }
  }
}

.no-profile-comp {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
}

.profile-view-wrapper {
  margin-bottom: 20px;

  span {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    display: inline-block;
  }
}

.card-wrapper {
  background-color: var(--primary-bg);
  background-image: radial-gradient(rgb(95, 130, 95), var(--bg-green));
  box-shadow: var(--box-shadow);
  padding: 30px;
  width: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;

  &:not(.editable) input,
  &:not(.editable) label {
    cursor: pointer;
  }

  &:hover:not(.editable),
  &:focus:not(.editable) {
    transform: scale(1.01);
    transition: 200ms;
    box-shadow: 0 0 8px var(--black);
    outline: none;
  }
}

.list-container {
  margin-top: 30px;

  span {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .list-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    place-items: center;
  }
}

.file-upload-wrapper {
  cursor: pointer;
  max-width: 250px;

  div {
    font-size: 10px;
  }

  .preview-img {
    height: 100px;
    max-width: 250px;
    display: block;
  }

  &:hover .upload-div {
    box-shadow: var(--box-shadow);
    transition: 200ms;
  }

  .upload-div {
    width: 250px;
    padding: 5px 10px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    box-shadow: 0 0 1px var(--black);
    cursor: pointer;

    span {
      color: var(--dark-of-primary-bg);
    }

    .icon {
      height: 30px;

      svg {
        fill: var(--dark-of-primary-bg);
      }
    }

    &:disabled {
      cursor: not-allowed;

      span {
        color: var(--grey);
      }

      img {
        filter: grayscale(0.6);
      }

      &:hover {
        box-shadow: 0 0 1px var(--black);
      }
    }
  }
}

.record-data-container {
  margin-top: 30px;

  span {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .record-data-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    place-items: center;
  }
}

.home {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap: 20px;

  font-family: 'Times New Roman';

  div {
    text-align: center;
  }

  .home-title {
    font-weight: 800;
    color: var(--white);
    font-size: 50px;
    margin-bottom: -30px;
  }

  .home-subtitle {
    font-weight: 500;
    color: var(--white);
    font-size: 28px;

    & div {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .home-para {
    font-weight: 500;
    font-size: 25px;
    color: var(--home-text);

    & div {
      font-size: inherit;
      color: inherit;
    }
  }

  .img-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    place-items: center;
    gap: 25px;
  }

  img {
    max-height: 275px;
  }
}